.yu-form {

    // The labels of class="input"
    .input {
        position: relative;
        display: block;
        font-weight: 400;

        .icon-pre+input {
            padding-left: 37px;
        }
    }

    .icon-pre, .icon-post {
        color: #a2a2a2;
    }


}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
}

input:focus {
    outline-offset: 0px;
}

.flex-nowrap {
    flex-wrap: nowrap !important;
}

.input-group {
    position: relative;
    display: flex;
    align-items: stretch;
    width: 100%;
}

.input-group-prepend {
    margin-right: -1px;
}

.input-group-prepend, .input-group-append {
    display: flex;
}

.input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.input-group-text {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    padding: .5rem .875rem;
    margin-bottom: 0;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.47;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #f3f3f3;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
}

.hu-well { font-family: 'Roboto', sans-serif; font-size: .875rem; min-height: 120px; }
.hu-well .primary { font-size: 22px; line-height: 24px; }
.hu-well span.sec-label, .hu-well span.primary-label { font-size: 11px; margin-bottom: 1px; text-align: right; display:block; color: #474747; }
.hu-well .primary li div.title { width: 200px; text-align: left; white-space: nowrap; overflow: hidden; } 
.hu-well .primary li div.amount { width: 100px; white-space: nowrap; overflow: hidden; } 

.hu-well ul { float: left; clear: left; } 
.hu-well .primary li { padding-right: 10px; } 
.hu-well .secondary li { padding-right: 10px; border-right: 1px solid #e1e1e3; } 
.hu-well li div { font-size: 13px; text-align: right; } 
.hu-well .primary li div { font-size: 23px; text-align: right; } 
.hu-well ul li { float: left; position: relative; padding-left: 5px; } 

.panel {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    background-color: #fff;
    -webkit-box-shadow: 0 0 13px 0 rgb(62 44 90 / 8%);
    box-shadow: 0 0 13px 0 rgb(62 44 90 / 8%);
    margin: 8px 8px 1rem 8px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,.09);
    border-bottom: 1px solid #e0e0e0;
    border-radius: 4px;
    -webkit-transition: border .5s ease-out;
    transition: border .5s ease-out;
}

.panel-hdr >:first-child {
    padding-left: 1rem;
}

.panel-hdr {
    background: #fff;
}

.panel-hdr {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: #fff;
    min-height: 3rem;
    border-bottom: 1px solid rgba(0,0,0,.07);
    border-radius: 4px 4px 0 0;
    -webkit-transition: background-color .4s ease-out;
    transition: background-color .4s ease-out;
}

.panel-hdr h2 {
    font-size: .875rem;
    margin: 0;
    color: #333;
    font-weight: 500;
}

.panel .panel-container {
    position: relative;
    border-radius: 0 0 4px 4px;
}

.panel .panel-container .panel-content {
    padding: 1rem 1rem;
}
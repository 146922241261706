#container {
    width: 100%;
    height: 100vh;
    background: #fff;
    display: grid;
    grid-template-rows: 68px auto 80px;
    grid-template-columns: 1;
}

header {
    grid-column: 1;
    grid-row: 1 / 2;
    background: blue;
}

#header {
    display: flex;
    flex: 0 0 auto;
    height: 68px;
    margin-bottom: 0;
    padding: 0 13px 0 0;
    background-color: #fff;
    background-image: linear-gradient(to bottom,#fff,#f2f2f2);
    box-shadow: 0 0 28px 0 rgb(86 61 124 / 13%);
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-repeat: repeat-x;
    position: relative;
    z-index: 905;
}

main {
    width: 100%;
    grid-row: 2 / 3;
    grid-column: 1;
    justify-content: center;
}

footer {
    grid-column: 1;
    grid-row: 3 / 4;
    background: pink;
}

.footer {
    width: 100%;
    height: 100%;
    border-top: 1px solid #CECECE;
    background-color: #231b16;
}

#main-grid {
    max-width: 1400px;
    justify-self: center;
}

content {
    width: 100%;
    height: 100vh;
    background-color: #ebecec;
    display: grid;
    grid-template-rows: 1;
    grid-template-columns: 262px auto 262px;
}

.item-left {
    grid-column: 1 / 2;
    grid-row: 1;
}

.item-center {
    grid-column: 2 / 3;
    grid-row: 1;
}

.item-home-right {
    grid-column: 2 / 4;
    grid-row: 1;
}

.cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: auto;     
    grid-gap: 1rem;
}

.d-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
}

.ml-auto {
    margin-left: auto !important;
}

.header-icon:not(.btn) {
    min-width: 3.125rem;
    text-align: center;
    overflow: visible;
    text-decoration: none;
}

.nav-elem {
    min-width: 3.125rem;
    margin-left: 0.8rem;
}

.user-image {
    border-radius: 3px;
    width: 32px;
}
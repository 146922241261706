*, ::before, ::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.input-group-text {
	transition: all 0.15s ease-in-out, 
}

.input-group > .form-control {
    position: relative;
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0;
}

.input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.32em + 1rem + 2px);
    padding: .5rem .875rem;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.47;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #c5c5c5;
    border-radius: 4px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out, -webkit-box-shadow .15s ease-in-out;
}

.input-group > .form-control:focus {
    z-index: 3;
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #66afe9;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-group {
    margin-bottom: 1rem;
}

label {
    display: inline-block;
    margin-bottom: .3rem;
}

.form-label {
    font-weight: 500;
}

a { color: #428bca; }

.font-xs { font-size: 74% !important; }
.font-sm { font-size: 78% !important; }
.font-xl { font-size: 176% !important; }

/** -- Material dialog -- */

.login-modal {
    text-align: center !important;
    
    mat-dialog-container {
        padding: 20px;
        overflow: none;
        
        h4 {
            margin-bottom: 22px;
        }

        .mat-dialog-content {
            width: 100%;
            padding: 0 20px;
            overflow: visible;
        }
    }

}


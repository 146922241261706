.nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
    width: 100%;
}

dl, ol, ul {
	margin-top: 0;
	margin-bottom: 1rem;
}

.nav-tabs-clean {
	height: 45px;
	border-bottom: 1px solid rgba(0,0,0,.1);
}

.nav-tabs-right {
    justify-content: flex-end;
}

.push {
    margin-left: auto;
}

.nav-tabs .nav-item {
	margin-bottom: 0;
}

.nav-tabs .nav-item .nav-link.active:not(:hover) {
	color: #333;
}

.nav .nav-link:not([class^="btn-"]):not([class*=" btn-"]) {
	font-weight: 500;
	font-size: .8125rem;
}

.nav-link {
	display: block;
	padding: .5rem 1.125rem;

    &.title {
        padding: .5rem 0;
    }
}

.nav-tabs-clean .nav-item .nav-link.active {
	border-bottom: 2px solid #886ab5;
	color: #886ab5;
}

.nav-tabs-clean .nav-item .nav-link {
	border-radius: 0;
	border: 0;
	height: 45px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom: 1px solid transparent;
}
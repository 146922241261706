button {
    cursor: pointer;
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.15rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/**
 * Button sizes
 */
.btn-reg {
    padding: .25rem .5rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .2rem;
}

.btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem;
}

.btn-xs {
    width: 60px;
}

.btn-block {
    display: block;
    width: 100%;
}

.btn-facebook {
    width: 100%;
    color: #fff;
    font-size: 13px;
    font-family: Roboto;
    background-color: #3b5998;
    margin: 5px 0;
}

.btn-google {
    width: 100%;
    color: #fff;
    font-size: 13px;
    font-family: Roboto;
    background-color: #dd4b39;
    margin: 5px 0;
}

.btn-default {
    color: rgb(65, 65, 65);
    width: 100%;
    height: 32px;
    border: 1px solid #c5c5c5;
    font-size: 13px;
    font-weight: 600;
    background-color: #f3f3f3;
}

.btn-default:hover {
    color: rgb(30, 30, 30);
    border: 1px solid #909090;
    background-color: #e1e1e1;
}

.btn-primary {
    color: rgb(255, 255, 255);
    border-color: #2a669a;
    background-color: #428bca;
}

.btn-primary:hover {
    color: rgb(225, 225, 225);
    border-color: #204e76;
    background-color: #56a2e5;
}

.btn-success {
    color: rgb(255, 255, 255);
    height: 32px;
    font-size: 13px;
    font-weight: 600;
    background-color: #60Dc77;
}

.btn-green {
    color: #fff;
    width: 100%;
    height: 32px;
    font-size: 14px;
    font-weight: bold;
    background-color: #95d195;
}

.btn-warning {
    color: #fff;
    width: 100%;
    height: 32px;
    font-size: 14px;
    font-weight: bold;
    background-color: #ffa600;
}

.btn-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
}

.mat-dialog-actions > .btn-group {
    margin-bottom: -20px;
}
/* You can add global styles to this file, and also import other style files */

@use "assets/scss/application";

body {
    font-size: .8125rem;
    font-family: Roboto, sans-serif;
    margin: 0;
    padding: 0;
}

body ol, body ul { list-style-type: none; padding: 0 }

h1, h2, h3, h4 {
    margin: 0;
}

a { color: #428bca; cursor: pointer; }

a:link {
    text-decoration: none;
}

dl.hu dd { margin-bottom: 6px; font-size: 16px; }
dl.hu dd.pleft { margin-bottom: 6px; font-size: 16px; font-weight: bold; color: #dd4b39; }
dl.hu dt { font-weight: normal; }

.house { 
    height: 110px; 
    float: left;
    margin: -6px 2px 2px -4px;
}

#logged_user {
    font-weight: bold;
}

#loan_name {
    font-size: 1.8em;
    font-weight: bold;
    font-style: italic;
}

#page_header_zone {
    width: 1048px;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
}

.wd_left_side {
    width: 180px;
    margin: 0;
}

.wd_right_side {
    width: 208px;
}

.wd_main_details {
    margin-right: 215px;
    vertical-align: top;
    margin-left: 194px;
    width: 630px;
}

table#lst tr { cursor: pointer; }
table#lst tr.line1 { background: #ecf0ff; }
table#lst tr.line2 { background: #ffffff; }
table#lst { font-family: 'Roboto'; font-size: 11px; }

/** Transaction ajoutee pour une date **/
.tr {
    border-bottom: 1px dotted #98ac9b;
    color: #005505;
    background-color: #f6fff6;
}

/** Ligne que un versement a ete ajoute **/
.vs { 
    font-weight: bold;
}

/** Ligne qui represente la periode ou la semaine actuelle. **/
.today {
    color: #777000;
    font-weight: normal;
}

.hc-navig {
	padding: 4px;
	color: #102344;
	
	}

.hypo_link {
    color: #666666;
    cursor: pointer;
}

#ins_av {
    color: #700;
    width: 100%;
    text-align: left;
}


#ins_av ul {
    list-style: none;
    margin-left: 6px;
    padding-left: 1em;
   /* text-indent: -1em; */
}

#ins_av ul li {
    margin-bottom: 2px;
}

#ins_av ul li:before {
	content: "\00BB \0020";
}


/* ------------------- Inscription - newuser.php ---------------------*/

#newuserform {
    height: 550px;
}

.ins_titre {
    font-size: 1.5em;
    font-weight: bold;
    border-bottom: 2px solid #000;
    color: #800;
}

#ins_right {
    float: right;
}

#ins_error_pop {
    margin: 12px;
    border: 1px solid #a77;
    background-color: #ffff55;
    padding: 5px;

}


/* -------------- Bottom menu --------------- */

#bottominfo {

    color: #555;
    height: 240px;
    width: 100%;
    text-align: center;
    margin-top: 6em;
    
}

#support {
    padding: 8px;
}


#botmenu ul {
    color: #555;
    font-weight: bold;
    list-style: none;
    margin: 4px 0 5px 0;
    padding: 0px;

}

#botmenu li {
    color: #555;
    font-weight: normal;
    display: inline;
    border-left: 1px solid #777;
    padding-left: 4px;


}

#botmenu li.first {
	margin-left: 0;
	border-left: none;
	list-style: none;
	display: inline;
	}

#botmenu a:hover {
    text-decoration: underline
}

#botmenu h4 {
    margin: 8px 3px 5px 0;
    padding: 0;
}


#disqus_thread {
    margin-top: 48px;
}



h5.lined:before, h5.lined:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 36%;
    height: 1px;
    content: '';
    background-color: #ccc;
}

h5.lined {
    position: relative;
    font-size: 12px;
    font-weight: bold;
    z-index: 1;
    overflow: hidden;
    text-align: center;
}

h5.lined:before {
    margin-left: -38%;
    text-align: right;
}

h5.lined:after {
    margin-left: 2%;
    text-align: left;
}

.sm-font {
    font-size: 11px;
}

.hu-main {
    width: 100%;
    height: 840px;
    background: url(assets/img/stocktest1.jpg) no-repeat;
    background-size: 100%;
}

.filet {
    width: 100%;
    height: 100%;
    padding: 20px;
    background-color: rgba(255, 255, 255, 0.8);
}



.hu-scen {
    cursor: pointer;
    margin: 0 6px 6px 0;
}

.case {
    font-family: 'OpenSans';
    font-size: 12px;
    border: 3px solid transparent;
    margin: -2px;

    >.name {
        color: #000;
        font-weight: 600;
    }

    &.active {
        color: white;
        padding: 1px;
        border: 2px solid #010d5a22;
        background-color: #000000ff;
        border-radius: 3px;

        >.name {
            color: white;
            font-weight: 700;
        }
    }
}

.case:hover {
    cursor: pointer;
    padding: 1px;
    border: 2px solid #010d5a22;
    background-color: #00316833;
    border-radius: 3px;
}

.case a:link {
    text-decoration: none;
}

